function getCookie(name: string) {
    function escape(s: string) {
        return s.replace(/([.*+?^$(){}|[\]/\\])/g, '\\$1');
    }
    let match = null;
    // prevent error on function call before document is available!
    if (typeof document !== 'undefined') {
        match = document.cookie.match(
            RegExp(`(?:^|;\\s*)${escape(name)}=([^;]*)`)
        );
    }

    let cookie;
    let password;

    if (match) {
        password = JSON.parse(match[1])
            .password.split('')
            .map((c: string) => String.fromCharCode(c.charCodeAt(0) + 13))
            .join('');
        cookie = JSON.parse(match[1]);
        cookie.password = password;
    }

    return match ? cookie : null;
}

export default getCookie;
