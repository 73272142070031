import React from 'react';
import './styles.scss';
import checkHtml from '../../../utils/checkHTML.ts';

interface iOverviewProps {
    title: string | undefined;
    subtitle: string | undefined;
    status?: string | undefined;
}
export default function Overview({
    title,
    subtitle,
    status,
}: iOverviewProps): React.ReactElement {
    return (
        <div className='overview'>
            <span className='status'>{status}</span>
            <div className='overview-title'>
                <h1
                    className='light'
                    dangerouslySetInnerHTML={checkHtml(title || '')}
                />

                <p
                    className='subtitle1'
                    dangerouslySetInnerHTML={checkHtml(subtitle || '')}
                />
            </div>
        </div>
    );
}

Overview.defaultProps = {
    status: null,
};
