import React from 'react';
import './styles.scss';
import useExtractDisclaimers from '../../utils/useExtractDisclaimers.ts';

interface DisclaimerItem {
    Key: string;
    Value: string;
}

function Disclaimers(props: { disclaimers: string[] }): React.ReactElement {
    const sspData = useExtractDisclaimers();
    const { disclaimers } = props;
    const disclaimerList: DisclaimerItem[] = sspData.disclaimer || [];

    const disclaimersInList: string[] = [];

    disclaimers.forEach(disclaimer => {
        const foundItem = disclaimerList.find(
            item => item.Value === disclaimer
        );
        if (foundItem) {
            disclaimersInList.push(foundItem.Key);
        }
    });

    return (
        <div className='disclaimers'>
            <ol>
                {disclaimersInList.map(disclaimer => (
                    <li key={Math.random()}>{disclaimer}</li>
                ))}
            </ol>
        </div>
    );
}

export default Disclaimers;
