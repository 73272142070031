import React from 'react';
import Overview from '../../Page/Overview';
import './styles.scss';
import {
    disclaimerCount,
    splitDisclaimers,
} from '../../../utils/checkForDisclaimers.ts';
import useExtractDisclaimers from '../../../utils/useExtractDisclaimers.ts';
import { iDisclaimers } from '../../../utils/interfaces.ts';
import SubPageContent from './SubPageContent.tsx';

function SubPage({ uid }: { uid: string }): React.ReactElement {
    const sspData = useExtractDisclaimers();
    const subpage = sspData.subPages.find(page => page.uid === uid);
    let title = subpage?.title;
    let subtitle = subpage?.subtitle;
    let text = subpage?.intro;

    let disclaimers = [];
    if (title?.discID) disclaimers.push(title.discID);
    if (subtitle?.discID) disclaimers.push(subtitle.discID);
    if (text?.discID) disclaimers.push(text.discID);
    disclaimers = splitDisclaimers(disclaimers);

    const disclaimerCountList = [subtitle, title, text];

    const correctDisclaimers: {
        disclaimers: iDisclaimers[];
        supCount: number;
    } = disclaimerCount(disclaimerCountList, 0) as {
        disclaimers: iDisclaimers[];
        supCount: number;
    };
    [title, subtitle, text] = correctDisclaimers.disclaimers;

    return (
        <div className='sub-page'>
            <Overview title={title?.key} subtitle={subtitle?.key} />
            <SubPageContent
                text={text}
                count={correctDisclaimers.supCount}
                disclaimers={disclaimers}
                uid={uid}
            />
        </div>
    );
}

export default SubPage;
