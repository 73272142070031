import React, { useState } from 'react';
import './styles.scss';
import i18next from 'i18next';
import { iSspProviderContext } from '../../../utils/SspProvider/interfaces.ts';
import { SET_CONTENT_ACTION } from '../../../utils/SspProvider/store.ts';

interface ILanguageProps {
    languages: Array<string>;
    store: iSspProviderContext;
}
export default function LanguageSwitch({
    languages,
    store,
}: ILanguageProps): React.ReactElement {
    const { language } = i18next;

    const [GermanActive, setGermanActive] = useState(language === 'de-DE');
    const [EnglishActive, setEnglishActive] = useState(language === 'en-US');

    const handleClick = () => {
        i18next.changeLanguage(
            i18next.language === 'de-DE' ? 'en-US' : 'de-DE'
        );

        if (
            store.state.ssp &&
            store.state.ssp.projectModel.asModel.contentTreeChildren.length > 0
        ) {
            store.state.ssp.setCurrentLanguage(i18next.language);
            store.dispatch({
                type: SET_CONTENT_ACTION,
                value: store.state.ssp.projectModel.asModel
                    .contentTreeChildren[0],
            });
        }

        setGermanActive(!GermanActive);
        setEnglishActive(!EnglishActive);
    };

    return (
        <div className='language-switch'>
            {languages.length > 1 && (
                <>
                    <button
                        type='button'
                        className={`${GermanActive ? 'active' : ''}`}
                        onClick={handleClick}
                    >
                        DE
                    </button>
                    <p> | </p>
                    <button
                        type='button'
                        className={`${EnglishActive ? 'active' : ''}`}
                        onClick={handleClick}
                    >
                        EN
                    </button>
                </>
            )}
        </div>
    );
}
