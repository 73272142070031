import useAdapter from './useAdapter.ts';
import {
    iOverlay,
    iOverlayDisc,
    iSubpage,
    iSubpageDisc,
} from './interfaces.ts';
import {
    checkKeyValuePairForDisclaimers,
    checkStringForDisclaimers,
} from './checkForDisclaimers.ts';

function useExtractDisclaimers() {
    const sspData = useAdapter();
    const { headerImg } = sspData;
    const { headerVideo } = sspData;
    const modelName = checkStringForDisclaimers(sspData.modelName);
    const subtitle = checkStringForDisclaimers(sspData.subtitle);
    const vehicleData = checkKeyValuePairForDisclaimers(sspData.vehicleData);
    const { disclaimer } = sspData;

    const mainPageOverlays = sspData.mainPageOverlays.map(
        (overlay: iOverlay): iOverlayDisc => ({
            title: checkStringForDisclaimers(overlay.title),
            tile_title: checkStringForDisclaimers(overlay.tile_title),
            subtitle: checkStringForDisclaimers(overlay.subtitle),
            description: checkStringForDisclaimers(overlay.description),
            uid: overlay.uid,
            price: checkStringForDisclaimers(overlay.price),
            price_title: checkStringForDisclaimers(overlay.price_title),
            preview_image: overlay.preview_image,
            video: overlay.video,
            tech_data: overlay.tech_data && {
                entries: overlay.tech_data.entries.map(entry => ({
                    id: entry.id,
                    name: checkStringForDisclaimers(entry.name),
                    entries: checkKeyValuePairForDisclaimers(entry.entries),
                })),
            },
        })
    );

    const secondDisplayUrl = sspData.second_display.url;
    const secondDisplayType = sspData.second_display.type;

    const { status } = sspData;
    const languages = '';
    const subPages = sspData.subPages.map(
        (subpage: iSubpage): iSubpageDisc => ({
            title: checkStringForDisclaimers(subpage.title),
            subtitle: checkStringForDisclaimers(subpage.subtitle),
            intro: checkStringForDisclaimers(subpage.intro),
            uid: subpage.uid,
            overlays: subpage.overlays.map(
                (overlay: iOverlay): iOverlayDisc => ({
                    title: checkStringForDisclaimers(overlay.title),
                    tile_title: checkStringForDisclaimers(overlay.tile_title),
                    subtitle: checkStringForDisclaimers(overlay.subtitle),
                    price: checkStringForDisclaimers(overlay.price),
                    price_title: checkStringForDisclaimers(overlay.price_title),
                    description: checkStringForDisclaimers(overlay.description),
                    uid: overlay.uid,
                    preview_image: overlay.preview_image,
                    video: overlay.video,
                })
            ),
        })
    );

    return {
        headerImg,
        headerVideo,
        modelName,
        subtitle,
        vehicleData,
        disclaimer,
        mainPageOverlays,
        second_display: {
            url: secondDisplayUrl,
            type: secondDisplayType,
        },
        status,
        languages,
        subPages,
    };
}

export default useExtractDisclaimers;
