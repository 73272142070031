import React from 'react';
import PreviewItems from '../PreviewItems';
import Disclaimers from '../../Disclaimers';
import { splitDisclaimers } from '../../../utils/checkForDisclaimers.ts';
import { iDisclaimers } from '../../../utils/interfaces.ts';
import useExtractDisclaimers from '../../../utils/useExtractDisclaimers.ts';
import UpperText from '../UpperTableOrText/UpperText.tsx';

interface iSubPageProps {
    count: number;
    disclaimers: string[];
    uid: string;
    text: iDisclaimers;
}

function SubPageContent({
    count,
    disclaimers,
    uid,
    text,
}: iSubPageProps): React.ReactElement {
    const sspData = useExtractDisclaimers();
    let disclaimerList: string[];
    const overlayTitles =
        sspData.subPages
            .find(subpage => subpage.uid === uid)
            ?.overlays.map(overlay => overlay.tile_title || overlay.title) ||
        [];

    function processOverlayTitles(titles: iDisclaimers[]): string[] {
        const disclaimersArray: string[] = [];
        titles.forEach(item => {
            if (item.discID !== '') {
                disclaimersArray.push(item.discID);
            }
        });
        return disclaimersArray;
    }

    const overlayDisclaimers =
        overlayTitles && processOverlayTitles(overlayTitles);

    disclaimerList = [...disclaimers, ...overlayDisclaimers];

    disclaimerList = splitDisclaimers(disclaimerList);

    return (
        <>
            <UpperText text={text?.key} />
            <PreviewItems main={false} subpageUid={uid} count={count} />
            <Disclaimers disclaimers={disclaimerList} />
        </>
    );
}

export default SubPageContent;
