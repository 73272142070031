import React from 'react';
import i18next from 'i18next';
import checkHtml from '../../../utils/checkHTML.ts';

interface PreviewItemProps {
    title: string;
    img: string;
    onClick: () => void;
}
function PreviewItem({
    onClick,
    title,
    img,
}: PreviewItemProps): React.ReactElement {
    return (
        <button className='preview-item' type='button' onClick={onClick}>
            <div className='img'>
                <img src={img} alt='preview' />{' '}
            </div>
            <div className={`img-caption${title === '' ? ' empty' : ''}`}>
                <h3
                    className='light'
                    dangerouslySetInnerHTML={checkHtml(title)}
                />
                <p>{i18next.t('ShowMore')}</p>
                <svg>
                    <use xlinkHref='#chevron-right' id='chevron-right' />
                </svg>
            </div>
        </button>
    );
}
export default PreviewItem;
