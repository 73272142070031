import React from 'react';
import './styles.scss';
import checkHtml from '../../../utils/checkHTML.ts';

function UpperText({ text }: { text: string | undefined }): React.ReactElement {
    return (
        <div className='upper-text'>
            <p dangerouslySetInnerHTML={checkHtml(text || '')} />
        </div>
    );
}

export default UpperText;
