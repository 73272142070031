import React, { useContext } from 'react';
import i18next from 'i18next';
import { goBack } from '../../utils/SspProvider/dispatchFunctions.ts';
import Common from './Common.tsx';
import OverlayEnum from '../../utils/enums.ts';
import SspProviderContext from '../../utils/SspProvider/context.ts';
import { iOverlayDisc } from '../../utils/interfaces.ts';
import { iOverlayProps } from './interfaces.ts';

export default function Overlay({
    overlay,
}: iOverlayProps): React.ReactElement {
    const store = useContext(SspProviderContext);

    const closeBtn = () => (
        <button
            className='close-btn'
            type='button'
            onClick={() => goBack(store)}
        >
            {i18next.t('Close')}
        </button>
    );

    function overlayContent(overlay: OverlayEnum | iOverlayDisc) {
        if (overlay as iOverlayDisc) {
            return (
                <Common overlay={overlay as iOverlayDisc} closeBtn={closeBtn} />
            );
        }

        return null;
    }

    if (overlay === null) {
        return <div />;
    }

    return (
        <div
            className='overlay-container-inner'
            role='button'
            tabIndex={0}
            onClick={e => {
                if (e.target === e.currentTarget) goBack(store);
            }}
            onKeyDown={e => {
                if (e.key === 'Escape') {
                    goBack(store);
                }
            }}
        >
            {overlayContent(overlay)}
            <div className='close-btn-container'>{closeBtn()}</div>
        </div>
    );
}
