import './techdata.scss';
import React from 'react';
import Disclaimers from '../Disclaimers';
import {
    disclaimerCount,
    iKeyValueDisclaimers,
    splitDisclaimers,
} from '../../utils/checkForDisclaimers.ts';
import { iDisclaimers, iTechDataDisc } from '../../utils/interfaces.ts';
import checkHtml from '../../utils/checkHTML.ts';

function TechData(overlay: any): React.ReactElement {
    const { overlay: currentOverlay } = overlay;
    let title =
        currentOverlay?.title !== ''
            ? currentOverlay?.title
            : currentOverlay?.tile_title;
    let subtitle = currentOverlay?.subtitle;
    const techData = currentOverlay?.tech_data;
    let disclaimers: string[] = [];
    if (title?.discID) disclaimers.push(title.discID);
    if (subtitle?.discID) disclaimers.push(subtitle.discID);
    const disclaimerCountList: iDisclaimers[] = [title, subtitle];
    const correctDisclaimers = disclaimerCount(disclaimerCountList, 0);
    [title, subtitle] = correctDisclaimers.disclaimers;

    function updateTechDataDisclaimers(data: iTechDataDisc) {
        data.entries.forEach(entry => {
            let { supCount } = correctDisclaimers;
            entry.entries.forEach(e => {
                if (
                    e.key.includes('<sup>%DISC%</sup>') ||
                    e.key.match(/<sup>\d+<\/sup>/)
                ) {
                    disclaimers.push(e.discIDKey);
                    e.key = e.key.replace(/<sup>(%DISC%|\d+)<\/sup>/g, () => {
                        supCount += 1;
                        return `<sup>${supCount}</sup>`;
                    });
                }
                if (
                    (e.value && e.value.includes('<sup>%DISC%</sup>')) ||
                    e.value.match(/<sup>\d+<\/sup>/)
                ) {
                    disclaimers.push(e.discIDValue);
                    e.value = e.value.replace(
                        /<sup>(%DISC%|\d+)<\/sup>/g,
                        () => {
                            supCount += 1;
                            return `<sup>${supCount}</sup>`;
                        }
                    );
                }
            });
        });
        return disclaimers;
    }

    updateTechDataDisclaimers(techData);

    disclaimers = splitDisclaimers(disclaimers);

    return (
        <div className='overlay-content'>
            <div
                className={`heading-area${currentOverlay.tech_data ? '-tech' : ''}`}
            >
                <p dangerouslySetInnerHTML={checkHtml(subtitle?.key)} />

                <h2 dangerouslySetInnerHTML={checkHtml(title?.key)} />
            </div>
            <div className='scrollable'>
                <div
                    className={`padding ${currentOverlay.tech_data ? 'tech' : ''}`}
                >
                    {currentOverlay.tech_data?.entries.map(
                        (entry: {
                            id: string;
                            name: iDisclaimers;
                            entries: iKeyValueDisclaimers[];
                        }) => (
                            <div className='tech-data' key={entry.name.key}>
                                <h3
                                    dangerouslySetInnerHTML={checkHtml(
                                        entry?.name.key
                                    )}
                                />

                                {entry.entries.map(e => (
                                    <div
                                        className='table-row'
                                        key={e.key + e.value}
                                    >
                                        <p
                                            dangerouslySetInnerHTML={checkHtml(
                                                e.key
                                            )}
                                        />
                                        <p
                                            dangerouslySetInnerHTML={checkHtml(
                                                e.value
                                            )}
                                        />
                                    </div>
                                ))}
                            </div>
                        )
                    )}
                </div>
                {disclaimers.length > 0 && (
                    <Disclaimers disclaimers={disclaimers} />
                )}
            </div>
        </div>
    );
}

export default TechData;
