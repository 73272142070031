import React from 'react';
import './styles.scss';
import VideoPlayer from '../VideoPlayer';

interface HeaderContentProps {
    img: string;
    video?: string;
}

export default function HeaderContent({
    img,
    video,
}: HeaderContentProps): React.ReactElement {
    return (
        <div className='header-content'>
            {video ? (
                <VideoPlayer video={video} />
            ) : (
                <img src={img} alt='header' />
            )}
        </div>
    );
}

HeaderContent.defaultProps = {
    video: null,
};
