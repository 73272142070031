import React, { useContext, useEffect, useState } from 'react';
import i18next from 'i18next';
import SspProviderContext from '../SspProvider/context';
import Loading from '../../components/Loading';

function TranslationProvider({
    children,
    // setupLink,
    projectMPA,
}: {
    children: React.ReactNode;
    // setupLink?: string;
    projectMPA: string;
}): React.ReactElement {
    const store = useContext(SspProviderContext);
    const [loaded, setLoaded] = useState<Array<string>>([]);
    const [error, setError] = useState<string | null>(null);

    const getJSON = (local: string, project: string | undefined) => {
        if (!project) return Promise.resolve();
        const lStorage = JSON.parse(
            localStorage.getItem(`${local}-${project}`) || '{}'
        );
        const url = `https://api.phrase.com/v2/projects/${project}/locales/${local}/download?file_format=i18next`;

        const headers = {
            Authorization: `token ${
                import.meta.env.VITE_REACT_APP_PHRASE_TOKEN as string
            }`,
            ...(lStorage.time ? { 'If-Modified-Since': lStorage.time } : {}),
        };

        return fetch(url, { headers })
            .then(response => {
                if (!response.ok) {
                    return { localStorage: true };
                }
                return response.json();
            })
            .then(json => {
                let newJson = json;
                if (json.localStorage === true && lStorage.response) {
                    newJson = lStorage.response;
                } else {
                    localStorage.setItem(
                        `${local}-${project}`,
                        JSON.stringify({
                            time: new Date(Date.now()).toUTCString(),
                            response: newJson,
                        })
                    );
                }

                i18next.addResourceBundle(
                    local,
                    'translation',
                    newJson,
                    true,
                    true
                );
            })
            .catch(() => {
                if (lStorage.response) {
                    i18next.addResourceBundle(
                        local,
                        'translation',
                        lStorage.response,
                        true,
                        true
                    );
                } else {
                    setError(`Error fetching language ${local}!`);
                }
            });
    };

    async function downloadLanguages() {
        const languages = store.state.content?.languages || [];

        await Promise.all(
            languages.map(async local => {
                try {
                    await getJSON(
                        local,
                        projectMPA || 'f9f87bd9458531f2954d2e3b2152588e'
                    );
                    setLoaded(prevState => [...prevState, local]);
                } catch (error) {
                    setError('Error fetching languages!');
                }
            })
        );
    }

    useEffect(() => {
        if (store.state.content) {
            i18next.changeLanguage(store.state.content.languages[0]);
            document.documentElement.setAttribute(
                'lang',
                store.state.content.languages[0]
            );
            downloadLanguages();
        }
    }, [store.state.content]);

    if (
        store.state.content === null ||
        loaded.length < store.state.content.languages.length
    ) {
        return <Loading />;
    }
    if (error) {
        throw new Error(`Error: ${error}`); // todo add ErrorPage
    }

    return <div>{children}</div>;
}
export default TranslationProvider;
