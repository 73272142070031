import React from 'react';
import './App.scss';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import SspContextProvider from './utils/SspProvider/SspContextProvider.tsx';
import OverlaysMap from './components/Overlay/OverlaysMap.tsx';
import Label from './components/Label';
import TranslationProvider from './utils/TranslationProvider';
import ICON_HTML from './components/Icons';
import Page from './components/Page';
import getCookie from './utils/getCookie.ts';

declare module 'i18next' {}

i18next.use(initReactI18next).init({
    resources: {},
    lng: 'en-US',
    fallbackLng: 'en-US',
    returnNull: false,
    interpolation: {
        escapeValue: false,
    },
    saveMissing:
        (import.meta.env.VITE_REACT_APP_CHECK_KEYS as string) === 'true',
    missingKeyHandler: (lng, key, fallbackValue) => {
        console.warn(lng, key, fallbackValue);
    },
});
function App(): React.ReactElement {
    const params = new URLSearchParams(window.location.search);
    const showLabel = params.get('label') === 'true';
    // || setup?.label;
    const apk = params.get('apk') === 'true';
    const projectMPA = 'f9f87bd9458531f2954d2e3b2152588e';
    // const setupLink = ''; // todo
    const setup = getCookie('setup');
    const brand = setup?.brand;
    let touchTimer: number | undefined;

    const resetTimer = () => {
        if (touchTimer) {
            window.clearTimeout(touchTimer);
        }
        touchTimer = window.setTimeout(() => {
            window.clearTimeout(touchTimer);
            touchTimer = undefined;
            window.dispatchEvent(new CustomEvent('reset'));
        }, 3 * 60000); // 3min
    };

    window.addEventListener('click', resetTimer);

    return (
        <SspContextProvider
            // setupLink={setupLink}
            prepareOfflineUse={!apk || (apk && !showLabel)}
            showLabel={showLabel}
        >
            <div className={`${brand}`}>
                <TranslationProvider projectMPA={projectMPA}>
                    {!showLabel && (
                        <>
                            <div
                                className='svgtemplates'
                                dangerouslySetInnerHTML={{ __html: ICON_HTML }}
                            />
                            <div onTouchStart={resetTimer}>
                                <Page />
                                <OverlaysMap />
                            </div>
                        </>
                    )}
                    {showLabel && <Label brand='Audi' apk={apk} />}
                </TranslationProvider>
            </div>
        </SspContextProvider>
    );
}

export default App;
