import React, { useContext, useRef } from 'react';
import './styles.scss';
import Slider from 'react-slick';
import HeaderContent from '../HeaderContent';
import Footer from '../Footer';
import SubPage from '../SwipeableArea/SubPage';
import MainPage from '../SwipeableArea/MainPage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useExtractDisclaimers from '../../utils/useExtractDisclaimers.ts';
import { SET_OVERLAYS_ACTION } from '../../utils/SspProvider/store.ts';
import SspProviderContext from '../../utils/SspProvider/context.ts';

function Page(): React.ReactElement {
    const sspData = useExtractDisclaimers();
    const subpages = sspData.subPages;
    const store = useContext(SspProviderContext);
    const sliderRef = useRef<Slider>(null);

    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
    };

    const resetSlider = () => {
        store.dispatch({
            type: SET_OVERLAYS_ACTION,
            value: [],
        });
        if (sliderRef.current) sliderRef.current.slickGoTo(0); // Move to the first slide
    };

    window.addEventListener('reset', resetSlider);

    return (
        <div className='page'>
            <HeaderContent
                img={sspData.headerImg}
                video={sspData.headerVideo}
            />
            <div className='container-padding'>
                <Slider
                    className='sub-pages-slider'
                    ref={sliderRef}
                    dots={sliderSettings.dots}
                    infinite={sliderSettings.infinite}
                    speed={sliderSettings.speed}
                    slidesToShow={sliderSettings.slidesToShow}
                    slidesToScroll={sliderSettings.slidesToScroll}
                    adaptiveHeight={sliderSettings.adaptiveHeight}
                >
                    <MainPage />
                    {subpages.map(subpage => (
                        <SubPage key={subpage.uid} uid={subpage.uid} />
                    ))}
                </Slider>
            </div>
            <Footer />
        </div>
    );
}

export default Page;
