import './common.scss';
import React from 'react';
import HeaderContent from '../HeaderContent';
import { iCommonProps } from './interfaces.ts';
import Disclaimers from '../Disclaimers';
import {
    disclaimerCount,
    splitDisclaimers,
} from '../../utils/checkForDisclaimers.ts';
import { iDisclaimers } from '../../utils/interfaces.ts';
import TechData from './TechData.tsx';
import checkHtml from '../../utils/checkHTML.ts';
import getCookie from '../../utils/getCookie.ts';

function Common({ overlay }: iCommonProps): React.ReactElement {
    const currentOverlay = overlay;
    let subtitle = currentOverlay?.subtitle;
    let title =
        currentOverlay?.title.key !== ''
            ? currentOverlay?.title
            : currentOverlay?.tile_title;
    let description = currentOverlay?.description;
    let priceTitle = currentOverlay?.price_title;
    let price = currentOverlay?.price;
    let disclaimers: any[] = [];
    const setup = getCookie('setup');
    const brand = setup?.brand;

    if (brand === 'audi') {
        if (subtitle?.discID) disclaimers.push(subtitle.discID);
        if (title?.discID) disclaimers.push(title.discID);
        if (priceTitle?.discID) disclaimers.push(priceTitle.discID);
        if (price?.discID) disclaimers.push(price.discID);
        if (description?.discID) disclaimers.push(description.discID);

        disclaimers = splitDisclaimers(disclaimers);

        const disclaimerCountList: iDisclaimers[] = [
            subtitle,
            title,
            priceTitle,
            price,
            description,
        ];

        const correctDisclaimers: {
            disclaimers: iDisclaimers[];
            supCount: number;
        } = disclaimerCount(disclaimerCountList, 0) as {
            disclaimers: iDisclaimers[];
            supCount: number;
        };
        [subtitle, title, priceTitle, price, description] =
            correctDisclaimers.disclaimers;
    }

    if (brand === 'vw') {
        if (subtitle?.discID) disclaimers.push(subtitle.discID);
        if (title?.discID) disclaimers.push(title.discID);
        if (description?.discID) disclaimers.push(description.discID);
        if (priceTitle?.discID) disclaimers.push(priceTitle.discID);
        if (price?.discID) disclaimers.push(price.discID);

        disclaimers = splitDisclaimers(disclaimers);

        const disclaimerCountList: iDisclaimers[] = [
            subtitle,
            title,
            description,
            priceTitle,
            price,
        ];

        const correctDisclaimers: {
            disclaimers: iDisclaimers[];
            supCount: number;
        } = disclaimerCount(disclaimerCountList, 0) as {
            disclaimers: iDisclaimers[];
            supCount: number;
        };
        [subtitle, title, description, priceTitle, price] =
            correctDisclaimers.disclaimers;
    }

    const headingArea = !currentOverlay.tech_data && (
        <>
            {' '}
            <HeaderContent
                img={currentOverlay.preview_image}
                video={currentOverlay.video}
            />
            <div
                className={`heading-area${currentOverlay.tech_data ? '-tech' : ''}`}
            >
                <p dangerouslySetInnerHTML={checkHtml(subtitle?.key)} />
                <h2 dangerouslySetInnerHTML={checkHtml(title?.key)} />
            </div>
        </>
    );

    if (brand === 'vw') {
        return (
            <>
                {currentOverlay.tech_data && (
                    <TechData overlay={currentOverlay} />
                )}
                {!currentOverlay.tech_data && (
                    <div className='overlay-content'>
                        {headingArea}
                        <div className='scrollable'>
                            <div
                                className={`padding ${currentOverlay.tech_data ? 'tech' : ''}`}
                            >
                                {description.key && (
                                    <p
                                        className='description'
                                        dangerouslySetInnerHTML={checkHtml(
                                            description.key
                                        )}
                                    />
                                )}

                                {(priceTitle?.key || price?.key) && (
                                    <div className='price'>
                                        <p
                                            dangerouslySetInnerHTML={checkHtml(
                                                priceTitle?.key
                                            )}
                                        />

                                        <h1
                                            dangerouslySetInnerHTML={checkHtml(
                                                price?.key
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                            {disclaimers.length > 0 && (
                                <Disclaimers disclaimers={disclaimers} />
                            )}
                        </div>
                    </div>
                )}
            </>
        );
    }
    return (
        <>
            {currentOverlay.tech_data && <TechData overlay={currentOverlay} />}
            {!currentOverlay.tech_data && (
                <div className='overlay-content'>
                    {' '}
                    {headingArea}
                    <div className='scrollable'>
                        <div
                            className={`padding ${currentOverlay.tech_data ? 'tech' : ''}`}
                        >
                            {(priceTitle?.key || price?.key) && (
                                <div className='price'>
                                    <p
                                        dangerouslySetInnerHTML={checkHtml(
                                            priceTitle?.key
                                        )}
                                    />

                                    <h1
                                        dangerouslySetInnerHTML={checkHtml(
                                            price?.key
                                        )}
                                    />
                                </div>
                            )}
                            {description.key && (
                                <p
                                    className='description'
                                    dangerouslySetInnerHTML={checkHtml(
                                        description.key
                                    )}
                                />
                            )}
                        </div>
                        {disclaimers.length > 0 && (
                            <Disclaimers disclaimers={disclaimers} />
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default Common;
