import React, { useContext } from 'react';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import i18next from 'i18next';
import SSPContentFieldAccessor from 'sspcontentext/src/model/accessor/SSPContentFieldAccessor';
import SspProviderContext from './SspProvider/context.ts';
import { iDataModel, iOverlay, iSubpage, iTechData } from './interfaces.ts';
import { iSspProviderContext } from './SspProvider/interfaces.ts';

function adapter(
    sspData: SSPContentFieldAccessor,
    store: iSspProviderContext
): iDataModel {
    const headerImg =
        sspData.ref('headerasset').asAsset.fileFamily === 'video'
            ? sspData.ref('headerasset').asAsset.posterUrl
            : sspData.ref('headerasset').asAsset.url;
    const headerVideo =
        sspData.ref('headerasset').asAsset.fileFamily === 'video'
            ? sspData.ref('headerasset').asAsset.url
            : '';
    const modelName =
        (sspData.target.modelname &&
            sspData.target.modelname[i18next.language]) ||
        '';
    const subtitle =
        (sspData.target.subtitle &&
            sspData.target.subtitle[i18next.language]) ||
        '';
    const vehicleData = sspData.target.vehicledata[i18next.language] || {};
    const disclaimer =
        sspData.ref('showcarapp_disclaimer').asModel.target.disclaimers[
            i18next.language
        ] || {};

    const mainPageOverlays = (sspData.reflist('showcarapp_popups') || []).map(
        (overlay: SSPContentVO): iOverlay => {
            const overlayModel = overlay.asModel.target;

            return {
                title:
                    (overlayModel.title &&
                        overlayModel.title[i18next.language]) ||
                    '',
                tile_title:
                    (overlayModel.buttontitle &&
                        overlayModel.buttontitle[i18next.language]) ||
                    '',
                subtitle:
                    (overlayModel.subtitle &&
                        overlayModel.subtitle[i18next.language]) ||
                    '',
                description:
                    (overlayModel.description &&
                        overlayModel.description[i18next.language]) ||
                    '',
                uid: overlay.uid || '',
                price:
                    (overlay.asModel.target.price &&
                        overlay.asModel.target.price[i18next.language]) ||
                    '',
                price_title:
                    (overlay.asModel.target.pricetitle &&
                        overlay.asModel.target.pricetitle[i18next.language]) ||
                    '',
                preview_image:
                    overlay.asModel.ref('headerasset').asAsset.fileFamily ===
                    'video'
                        ? overlay.asModel.ref('headerasset').asAsset.posterUrl
                        : overlay.asModel.ref('headerasset').asAsset.url,
                video:
                    overlay.asModel.ref('headerasset').asAsset.fileFamily ===
                    'video'
                        ? overlay.asModel.ref('headerasset').asAsset.url
                        : '',
                tech_data: (overlay.asModel.reflist('engine') || []).map(
                    (data: SSPContentVO): iTechData => ({
                        entries: (data.asModel.reflist('entries') || []).map(
                            (entry: SSPContentVO) => ({
                                id: entry.asModel.str('categoryId') || '',
                                name:
                                    (entry.asModel.target.name &&
                                        entry.asModel.target.name[
                                            i18next.language
                                        ]) ||
                                    '',
                                entries:
                                    (entry.asModel.target.entries &&
                                        entry.asModel.target.entries[
                                            i18next.language
                                        ]) ||
                                    [],
                            })
                        ),
                    })
                )[0],
            };
        }
    );

    const secondDisplayUrl =
        sspData.ref('seconddisplay').asModel.ref('co2_asset').asAsset.url || '';
    const secondDisplayType =
        sspData.ref('seconddisplay').asModel.ref('co2_asset').asAsset
            .fileFamily || '';

    const status = sspData.obj('date').Timestamp || '';
    const languages = store.state.content?.languages || [];
    const subPages = (sspData.reflist('showcarapp_subpages') || []).map(
        (subpage: SSPContentVO): iSubpage => ({
            title:
                (subpage.asModel.target.title &&
                    subpage.asModel.target.title[i18next.language]) ||
                '',
            subtitle:
                (subpage.asModel.target.subtitle &&
                    subpage.asModel.target.subtitle[i18next.language]) ||
                '',
            intro:
                (subpage.asModel.target.introduction &&
                    subpage.asModel.target.introduction[i18next.language]) ||
                '',
            uid: subpage.uid || '',
            overlays: (subpage.asModel.reflist('showcarapp_popups') || []).map(
                (overlay: SSPContentVO): iOverlay => ({
                    title:
                        (overlay.asModel.target.title &&
                            overlay.asModel.target.title[i18next.language]) ||
                        '',
                    tile_title:
                        (overlay.asModel.target.buttontitle &&
                            overlay.asModel.target.buttontitle[
                                i18next.language
                            ]) ||
                        '',
                    subtitle:
                        (overlay.asModel.target.subtitle &&
                            overlay.asModel.target.subtitle[
                                i18next.language
                            ]) ||
                        '',
                    price:
                        (overlay.asModel.target.price &&
                            overlay.asModel.target.price[i18next.language]) ||
                        '',
                    price_title:
                        (overlay.asModel.target.pricetitle &&
                            overlay.asModel.target.pricetitle[
                                i18next.language
                            ]) ||
                        '',
                    description:
                        (overlay.asModel.target.description &&
                            overlay.asModel.target.description[
                                i18next.language
                            ]) ||
                        '',
                    uid: overlay.uid || '',
                    preview_image:
                        overlay.asModel.ref('headerasset').asAsset
                            .fileFamily === 'video'
                            ? overlay.asModel.ref('headerasset').asAsset
                                  .posterUrl
                            : overlay.asModel.ref('headerasset').asAsset.url,
                    video:
                        overlay.asModel.ref('headerasset').asAsset
                            .fileFamily === 'video'
                            ? overlay.asModel.ref('headerasset').asAsset.url
                            : '',
                })
            ),
        })
    );

    return {
        headerImg,
        headerVideo,
        modelName,
        subtitle,
        vehicleData,
        disclaimer,
        mainPageOverlays,
        second_display: {
            url: secondDisplayUrl,
            type: secondDisplayType,
        },
        status,
        languages,
        subPages,
    };
}

function useAdapter(): iDataModel {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content?.asModel;

    if (contentModel === undefined) {
        throw new Error('contentModel is undefined');
    }

    return React.useMemo(
        () => adapter(contentModel, store),
        [contentModel, store]
    );
}

export default useAdapter;
