const ICON_HTML = `<div style="display: none; visibility: hidden; width: 0; height: 0;">
   <svg xmlns="http://www.w3.org/2000/svg">
   
     <symbol id="chevron-right" viewBox="0 0 24 24">
          <path d="M12,17.813a1.655,1.655,0,0,1-1.189-.5l-9.786-10,.95-.93,9.785,10a.34.34,0,0,0,.48,0l9.784-10,.951.93-9.785,10A1.652,1.652,0,0,1,12,17.813Z" transform="translate(-0.099 24) rotate(-90)" />
     </symbol>
     
   </svg>
</div>`;
export default ICON_HTML;
