import React, { useContext } from 'react';
import i18next from 'i18next';
import SspProviderContext from '../../utils/SspProvider/context.ts';
import LanguageSwitch from './LanguageSwitch';
import './styles.scss';

function Footer(): React.ReactElement {
    const store = useContext(SspProviderContext);
    const languages = Object.keys(i18next.options.resources as object);

    return (
        <div className='footer'>
            <div className='sub-pages-indicators' />
            <LanguageSwitch languages={languages} store={store} />
        </div>
    );
}

export default Footer;
