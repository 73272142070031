function checkForUpdates(
    url: string,
    device: string,
    fetchOptions: {
        method: string;
        headers: {
            Authorization: string;
            'Service-Worker'?: string;
            'Cache-Control'?: string;
        };
    },
    label?: boolean
) {
    const checkURL = `${url}/module/content/bpca/depot/terminals/${device}/index.csv.hash`;
    let oldHash = '';
    const oldBuildInfo = localStorage.getItem('build-info');
    if (fetchOptions && fetchOptions.headers) {
        const fetchOptionsNew = { ...fetchOptions };
        fetchOptionsNew.headers['Service-Worker'] = 'false';
        fetchOptionsNew.headers['Cache-Control'] = 'max-age=0';
    }
    const localStorageKey = `build-info${label ? '-label' : ''}`;

    const reload = () => {
        console.warn('Update available!');

        if (!label && 'serviceWorker' in navigator) {
            navigator.serviceWorker
                .getRegistrations()
                .then(registrations => {
                    registrations.forEach(registration => {
                        registration.unregister();
                    });
                })
                .catch(err => {
                    console.error('service worker unregister failed: ', err);
                });
        }

        caches
            .keys()
            .then(keyList =>
                Promise.all(keyList.map(key => caches.delete(key)))
            );
        window.location.reload();
    };

    const fetchHash = () => {
        fetch(checkURL, fetchOptions)
            .then(response => {
                if (response.status === 204 || response.status === 711)
                    return oldHash;
                return response.text();
            })
            .then(hash => {
                if (oldHash === '') {
                    oldHash = hash;
                } else if (hash !== oldHash) {
                    localStorage.setItem('update-available', 'true');
                    reload();
                }
            })
            .catch(e => console.warn(e));
        fetch('./build-info.json', {
            headers: {
                'Service-Worker': 'false',
                'Cache-Control': 'max-age=0',
            },
        })
            .then(r => r.json())
            .then(newBuildInfo => {
                if (
                    oldBuildInfo &&
                    new Date(newBuildInfo.timestamp) >
                        new Date(JSON.parse(oldBuildInfo).timestamp)
                ) {
                    localStorage.setItem(
                        localStorageKey,
                        JSON.stringify(newBuildInfo)
                    );
                    reload();
                } else if (!oldBuildInfo) {
                    localStorage.setItem(
                        localStorageKey,
                        JSON.stringify(newBuildInfo)
                    );
                }
            })
            .catch(e => console.warn(e));
    };
    if (!label) fetchHash();
    setInterval(
        () => {
            if (label) {
                if (localStorage.getItem('update-available') === 'true') {
                    localStorage.removeItem('update-available');
                    reload();
                }
            }
        },
        0.5 * 60000 * (label ? 0.1 : 1)
    ); // 0.5min
}

export default checkForUpdates;
