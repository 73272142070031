import React from 'react';
import i18next from 'i18next';
import Overview from '../../Page/Overview';
import './styles.scss';
import {
    disclaimerCount,
    splitDisclaimers,
} from '../../../utils/checkForDisclaimers.ts';
import MainPageContent from './MainPageContent.tsx';
import useExtractDisclaimers from '../../../utils/useExtractDisclaimers.ts';
import { iDisclaimers } from '../../../utils/interfaces.ts';

export default function MainPage(): React.ReactElement {
    const sspData = useExtractDisclaimers();
    let title = sspData.modelName;
    let { subtitle } = sspData;
    const status =
        sspData.status &&
        new Date(sspData.status).toLocaleDateString(i18next.language);

    let disclaimers: string[] = [];
    if (title?.discID) disclaimers.push(title.discID);
    if (subtitle?.discID) disclaimers.push(subtitle.discID);

    disclaimers = splitDisclaimers(disclaimers);

    const disclaimerCountList = [subtitle, title];

    const correctDisclaimers = disclaimerCount(disclaimerCountList, 0) as {
        disclaimers: iDisclaimers[];
        supCount: number;
    };
    [title, subtitle] = correctDisclaimers.disclaimers;

    return (
        <div className='main-page'>
            <Overview
                title={title?.key}
                subtitle={subtitle?.key}
                status={status}
            />
            <MainPageContent
                count={correctDisclaimers.supCount}
                disclaimers={disclaimers}
            />
        </div>
    );
}
