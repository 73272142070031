import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.scss';
import registerSW from './utils/serviceWorkerRegistration.ts';
import getCookie from './utils/getCookie.ts';

const params = new URLSearchParams(window.location.search);

registerSW(getCookie('setup')?.password, 'mpa', ['/mpa/index.html']);

if (params.get('iframe') === 'true') {
    const url = new URL(window.location.href);
    url.searchParams.delete('iframe');
    ReactDOM.createRoot(document.getElementById('root')!).render(
        <iframe className='app' title='DID' src={url.toString()} />
    );
} else {
    ReactDOM.createRoot(document.getElementById('root')!).render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}
