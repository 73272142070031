import { iDisclaimers } from './interfaces.ts';

export interface iKeyValueDisclaimers {
    key: string;
    discIDKey: string;
    value: string;
    discIDValue: string;
}

export function checkStringForDisclaimers(
    input: string | undefined
): iDisclaimers {
    if (input === undefined) {
        return { key: '', discID: '' };
    }

    const key = input;
    let discID = '';

    const replacedKey = key.replace(/#([^#]+)#/g, (_match, p1) => {
        const sup = `<sup>%DISC%</sup>`;
        discID += `#${p1}#`;
        return sup;
    });

    return { key: replacedKey, discID };
}

function processString(input: string | undefined): {
    text: string;
    discID: string;
} {
    if (input === undefined) {
        return { text: '', discID: '' };
    }

    const text = input;
    let discID = '';

    const replacedText = text.replace(/#([^#]+)#/g, (_match, p1) => {
        const sup = `<sup>%DISC%</sup>`;
        discID += `#${p1}#`;
        return sup;
    });

    return { text: replacedText, discID };
}

export function checkKeyValuePairForDisclaimers(
    input: Array<{ [key: string]: string | undefined }>
): Array<iKeyValueDisclaimers> {
    return input.map(obj => {
        const processedKey = processString(obj.Key);
        const processedValue = processString(obj.Value);

        return {
            key: processedKey.text,
            discIDKey: processedKey.discID,
            value: processedValue.text,
            discIDValue: processedValue.discID,
        };
    });
}

export function splitDisclaimers(array: string[]): string[] {
    const result: string[] = [];

    array.forEach(str => {
        if (str.includes('##')) {
            const parts = str.split('#').filter(part => part.trim() !== '');
            result.push(...parts.map(part => `#${part.trim()}#`));
        } else {
            result.push(str);
        }
    });

    return result;
}

export function disclaimerCount(
    input: (iDisclaimers | iKeyValueDisclaimers | undefined)[],
    count: number
): {
    disclaimers: (iDisclaimers | iKeyValueDisclaimers | undefined)[];
    supCount: number;
} {
    if (input.length < 1) {
        return { disclaimers: [], supCount: count };
    }
    let supCount = count;

    const disclaimers = input.map(item => {
        if (item) {
            const newItem = item;
            if (
                newItem.key.includes('<sup>%DISC%</sup>') ||
                newItem.key.match(/<sup>\d+<\/sup>/)
            ) {
                newItem.key = newItem.key.replace(
                    /<sup>(%DISC%|\d+)<\/sup>/g,
                    () => {
                        supCount += 1;
                        return `<sup>${supCount}</sup>`;
                    }
                );
            }
            if (
                'value' in newItem &&
                newItem.value &&
                (newItem.value.includes('<sup>%DISC%</sup>') ||
                    newItem.value.match(/<sup>\d+<\/sup>/))
            ) {
                newItem.value = newItem.value.replace(
                    /<sup>(%DISC%|\d+)<\/sup>/g,
                    () => {
                        supCount += 1;
                        return `<sup>${supCount}</sup>`;
                    }
                );
            }
            return newItem;
        }

        return undefined;
    });
    return { disclaimers, supCount };
}
