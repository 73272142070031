import React from 'react';
import checkHtml from '../../../utils/checkHTML.ts';

interface upperTableElementProps {
    label: string;
    value: string;
}
export default function UpperTableElement({
    label,
    value,
}: upperTableElementProps): React.ReactElement {
    return (
        <div className='upper-table-element'>
            <p dangerouslySetInnerHTML={checkHtml(label)} />
            <b dangerouslySetInnerHTML={checkHtml(value)} />
        </div>
    );
}
