import React, { useContext } from 'react';
import PreviewItem from './PreviewItem.tsx';
import './styles.scss';
import { addOverlay } from '../../../utils/SspProvider/dispatchFunctions.ts';
import SspProviderContext from '../../../utils/SspProvider/context.ts';
import { iOverlayDisc } from '../../../utils/interfaces.ts';
import useExtractDisclaimers from '../../../utils/useExtractDisclaimers.ts';
import { disclaimerCount } from '../../../utils/checkForDisclaimers.ts';

interface iPreviewItemProps {
    main: boolean;
    count: number;
    subpageUid?: string;
}
export default function PreviewItems({
    main,
    subpageUid,
    count,
}: iPreviewItemProps): React.ReactElement {
    const store = useContext(SspProviderContext);
    const sspData = useExtractDisclaimers();
    const { mainPageOverlays } = sspData;
    const subPageOverlays = sspData.subPages.find(
        subpage => subpage.uid === subpageUid
    )?.overlays;
    let overlayTitles;
    if (main) {
        overlayTitles = sspData.mainPageOverlays.map(
            overlay => overlay.tile_title || overlay.title
        );
    } else {
        overlayTitles = subPageOverlays?.map(
            overlay => overlay.tile_title || overlay.title
        );
    }

    if (overlayTitles !== undefined) {
        disclaimerCount(overlayTitles, count);
    }

    function numberToString(num: number): string {
        const words = [
            'zero',
            'one',
            'two',
            'three',
            'four',
            'five',
            'six',
            'seven',
            'eight',
            'nine',
        ];

        if (num >= 0 && num < words.length) {
            return words[num];
        }

        return num.toString();
    }

    const mainPageLength = numberToString(mainPageOverlays.length);
    const subPageLength =
        subPageOverlays && numberToString(subPageOverlays.length);

    return (
        <div className='container'>
            {main ? (
                <div className={`preview-items ${mainPageLength}`}>
                    {mainPageOverlays.map((overlay: iOverlayDisc) => (
                        <PreviewItem
                            key={overlay.uid}
                            title={overlay.tile_title.key || overlay.title.key}
                            img={overlay.preview_image}
                            onClick={() => addOverlay(store, overlay)}
                        />
                    ))}
                </div>
            ) : (
                <div className={`preview-items ${subPageLength} sub`}>
                    {subPageOverlays &&
                        subPageOverlays.map((overlay: iOverlayDisc) => (
                            <PreviewItem
                                key={overlay.uid}
                                title={
                                    overlay.tile_title.key || overlay.title.key
                                }
                                img={overlay.preview_image}
                                onClick={() => addOverlay(store, overlay)}
                            />
                        ))}
                </div>
            )}
        </div>
    );
}

PreviewItems.defaultProps = {
    subpageUid: null,
};
