import React, { useContext } from 'react';
import Overlay from './index.tsx';
import SspProviderContext from '../../utils/SspProvider/context.ts';

export default function OverlaysMap(): React.ReactElement {
    const store = useContext(SspProviderContext);
    return (
        <>
            {store.state.overlays.map(overlay => (
                <React.Fragment key={`overlay-${overlay}`}>
                    <Overlay overlay={overlay} />
                </React.Fragment>
            ))}
            {store.state.overlays.length === 0 && <Overlay overlay={null} />}
        </>
    );
}
