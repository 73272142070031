import React, { useEffect, useState } from 'react';
import './styles.scss';
import canvasFromPDF from './canvasFromPDF.ts';
import useExtractDisclaimers from '../../utils/useExtractDisclaimers.ts';
// import Loading from '../Loading';

// todo commented things out which do not yet exist

let checkSWTimer: number | undefined;
export default function Label({
    brand,
    apk,
}: {
    brand: string;
    apk: boolean;
}): React.ReactElement {
    console.log(brand);

    const orientationFromParams = new URLSearchParams(
        window.location.search
    ).get('orientation');
    if (orientationFromParams)
        localStorage.setItem('orientation', orientationFromParams);
    const orientation =
        orientationFromParams || localStorage.getItem('orientation') || '';
    const sspData = useExtractDisclaimers();

    const { type } = sspData.second_display;
    const { url } = sspData.second_display;

    const [swReady, setSWReady] = useState<boolean>(false);

    useEffect(() => {
        if (type === 'pdf') {
            canvasFromPDF(url);
        }
    });
    // repeatedly check if sw is registered
    function checkSW() {
        if (navigator.serviceWorker.controller) {
            setSWReady(true);
        } else {
            if (checkSWTimer) window.clearTimeout(checkSWTimer);
            checkSWTimer = window.setTimeout(
                () => checkSW(),
                1000 // 1s
            );
        }
    }

    // avoid loading asset in apk second screen without a service worker to provide the authorization!
    if (
        apk &&
        !swReady &&
        (type === 'pdf' || type === 'jpg' || type === 'png') &&
        'serviceWorker' in navigator
    ) {
        checkSW();

        // return <Loading text={i18next.t('WaitingForPrimaryScreen')} />;
    }

    if (type === 'image') {
        return (
            <div className={`label ${orientation}`}>
                <img src={url} alt='second screen' />
                {/* <BackToSetup brand={brand} /> */}
            </div>
        );
    }

    if (type === 'pdf') {
        return (
            <div className={`label ${orientation}`}>
                <div id='canvas' />
                {/* <BackToSetup brand={brand} /> */}
            </div>
        );
    }

    return (
        <div className={`label ${orientation} label-default`}>
            <div className='wrapper'>
                <svg>
                    <use xlinkHref='#brand-icon' />
                </svg>
                <h1>Digital Info Display</h1>
            </div>
            {/* <BackToSetup brand={brand} /> */}
        </div>
    );
}
