import React from 'react';
import UpperTableElement from '../UpperTableOrText/UpperTableElement.tsx';
import '../UpperTableOrText/styles.scss';
import useExtractDisclaimers from '../../../utils/useExtractDisclaimers.ts';
import {
    disclaimerCount,
    iKeyValueDisclaimers,
    splitDisclaimers,
} from '../../../utils/checkForDisclaimers.ts';
import PreviewItems from '../PreviewItems';
import Disclaimers from '../../Disclaimers';
import { iDisclaimers } from '../../../utils/interfaces.ts';
import getCookie from '../../../utils/getCookie.ts';

interface iMainPageProps {
    count: number;
    disclaimers: string[];
}
export default function MainPageContent({
    count,
    disclaimers,
}: iMainPageProps): React.ReactElement {
    const sspData = useExtractDisclaimers();
    const { vehicleData } = sspData;
    const { length } = vehicleData;
    let disclaimerList: string[];
    const overlayTitles = sspData.mainPageOverlays.map(
        overlay => overlay.tile_title || overlay.title
    );
    const setup = getCookie('setup');
    const brand = setup?.brand;

    function processVehicleData(data: iKeyValueDisclaimers[]): string[] {
        const disclaimersArray: string[] = [];
        data.forEach(item => {
            if (item.discIDKey !== '') {
                disclaimersArray.push(item.discIDKey);
            }

            if (item.discIDValue !== '') {
                disclaimersArray.push(item.discIDValue);
            }
        });

        return disclaimersArray;
    }

    function processOverlayTitles(titles: iDisclaimers[]): string[] {
        const disclaimersArray: string[] = [];
        titles.forEach(item => {
            if (item.discID !== '') {
                disclaimersArray.push(item.discID);
            }
        });
        return disclaimersArray;
    }

    const vehicleDisclaimers = processVehicleData(vehicleData);
    const overlayDisclaimers = processOverlayTitles(overlayTitles);

    disclaimerList = [
        ...disclaimers,
        ...vehicleDisclaimers,
        ...overlayDisclaimers,
    ];

    const correctDisclaimersVehicleData = disclaimerCount(vehicleData, count);

    disclaimerList = splitDisclaimers(disclaimerList);

    return (
        <div className='main-page-content'>
            <div
                className={`upper-table${(length <= 4 && brand === 'audi') || (length <= 3 && brand === 'vw') ? ' short45' : ''}`}
            >
                {vehicleData.map((data: iKeyValueDisclaimers) => (
                    <UpperTableElement
                        key={data.key + data.value}
                        label={data.key}
                        value={data.value}
                    />
                ))}{' '}
            </div>
            <PreviewItems main count={correctDisclaimersVehicleData.supCount} />
            <Disclaimers disclaimers={disclaimerList} />
        </div>
    );
}
