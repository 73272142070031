import SSPContentExt from 'sspcontentext';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import { iSspProviderState } from './interfaces';
import OverlayEnum from '../enums.ts';
import { iOverlayDisc } from '../interfaces.ts';

export const SET_CONTENT_ACTION = 'SET_CONTENT';
export const SET_SSP_ACTION = 'SET_SSP';
export const SET_OVERLAYS_ACTION = 'SET_OVERLAYS';

export type ACTION =
    | { type: typeof SET_CONTENT_ACTION; value: SSPContentVO }
    | { type: typeof SET_SSP_ACTION; value: SSPContentExt }
    | {
          type: typeof SET_OVERLAYS_ACTION;
          value: Array<OverlayEnum | iOverlayDisc>;
      };

export const reducer = (
    state: iSspProviderState,
    action: ACTION
): iSspProviderState => {
    switch (action.type) {
        case SET_CONTENT_ACTION:
            return {
                ...state,
                content: action.value,
            };
        case SET_SSP_ACTION:
            return {
                ...state,
                ssp: action.value,
            };
        case SET_OVERLAYS_ACTION:
            return {
                ...state,
                overlays: action.value,
            };

        default:
            return state;
    }
};

export const INITIAL_STATE: iSspProviderState = {
    content: null,
    ssp: null,
    overlays: [],
};
